.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.contents {
  margin: 60px 20px 20px 20px;
}

.navbar-nav > .nav-item {
  margin: 0px 5px;
}

.footer {
  font-size: 14px;
  text-align: center;
  margin: 40px 20px 10px 20px;
}

.book-items > .nav-item {
  text-transform: uppercase;
}

.book-items > .nav-item > .active {
  font-weight: 700;
}

.author-bio {
  border-radius: 8px;
}

.section {
  margin-bottom: 30px;
}

.section .content {
  margin: 20px 10px;
}

.section .heading {
  margin-top: 30px;
  font-size: larger;
}

.section .heading.introduction {
  margin-top: 80px;
  font-size: larger;
  text-align: center;
}

.section .trailer {
  width: 348px;
  height: 200px;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.trailer-link {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 550;
  margin-bottom: 20px;
}

.book {
  width: 125px;
  height: 196px;
  margin: 10px;
  cursor: pointer;
}

.book-frame {
  background-image: url("../build/assets/misc/book-outline.png");
  background-repeat: no-repeat;
  background-size: 203px 217px;
  background-position: -20px 0px;
  width: 183px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.book-frame img {
  transform-style: preserve-3d;
  transform: perspective(210px) rotateY(-7deg);
  border-radius: 2px;
}

.book-large {
  width: 200px;
  height: 300px;
  margin: 40px 0px;
}

.book-frame-large {
  background-image: url("../build/assets/misc/book-outline.png");
  background-repeat: no-repeat;
  background-size: 300px 340px;
  background-position: -26px 20px;
}

.book-frame-large img {
  transform-style: preserve-3d;
  transform: perspective(210px) rotateY(-7deg);
  border-radius: 2px;
}

.book-view-title {
  margin-top: 20px;
}

.book-view-buy {
  margin-left: 20px;
}

.audio-view-buy {
  margin-top: 10px;
  margin-left: 20px;
}

.amazon-button-image {
  height: 78px;
  width: 200px;
}

.search-return-home {
  text-align: center;
  margin-top: 40px;
}

.nav-back-button {
  text-align: right;
}

.news-item-date {
  margin-left: 30px;
}

.news-item-entry {
  margin-bottom: 1rem;
}

.news-item-entry p {
  margin-bottom: 0;
}

.subscribe-widget-container {
  margin-left: 10px;
}
.subscribe-widget-label {
  margin-bottom: 0px;
}

.subscribe-widget-input {
}

.subscribe-widget-button {
  margin-top: 5px;
  float: right;
}

.mark,
mark {
  padding: 0;
}

.cwhLogo {
  height: 30px;
}
